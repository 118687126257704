import { initializeApp } from "firebase/app";
import { getToken, getMessaging, onMessage } from "firebase/messaging";
import "firebase/messaging";
import firebase from "firebase/app";
export const firebaseConfig = {
    apiKey: "AIzaSyC8i-OYRd1Y0XzGAuX3cw0-3pVT-4_OPGU",
    authDomain: "sociality-notify.firebaseapp.com",
    databaseURL: "https://sociality-notify-default-rtdb.firebaseio.com",
    projectId: "sociality-notify",
    storageBucket: "sociality-notify.appspot.com",
    messagingSenderId: "942023076664",
    appId: "1:942023076664:web:824a89b807490b8de12fcd",
    measurementId: "G-WJ0GKF34LM"
};

export const firebaseApp = initializeApp(firebaseConfig);
