let url = "";
let SocketURL = "";
if (window.location.hostname == "localhost") {
  url = "http://localhost:5000/";
  SocketURL = "http://localhost:3500";
} else {
  url = "http://3.80.228.77:4038/";
  SocketURL = "http://3.80.228.77:4039/";
}

const environment = {
  apiUrl: url,
  SocketIoUrl: SocketURL,
  clientId:
    "491942618061-ojuufrcptglefemc7ao1djrp9gidh3s5.apps.googleusercontent.com",
};
export default environment;
