import React, { useEffect, useRef, useState } from "react";
import { CiSearch } from "react-icons/ci";
import Logo from "../TrendingPage/Dev-logo.jpg";
import { SlSettings } from "react-icons/sl";
import { IoMdCloudUpload } from "react-icons/io";
import Swal from "sweetalert2";
// import Swal from 'sweetalert2/dist/sweetalert2.js'
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";

import { HiHome } from "react-icons/hi";
import { GrSnapchat } from "react-icons/gr";
import { TbMessage } from "react-icons/tb";
import { useNavigate } from "react-router";
import ApiClient from "../../Apis/ApiClient";
import toast from "react-hot-toast";
import LoadingBar from "react-top-loading-bar";
import { useDispatch, useSelector } from "react-redux";
import { LOGOUT_SUCCESS } from "../../Redux/Action/Action";
import environment from "../../Environment/environment";
import axios from "axios";
import { io } from "socket.io-client";
export default function TrendingSec() {
  // const Navigate = useNavigate()
  const history = useNavigate();
  const ref = useRef(null);
  const Dispatch = useDispatch();
  const SocketRef = useRef(null);
  const [data, setData] = useState({});
  const [Story, setStory] = useState([]);
  const [Form, setform] = useState({ title: "" });
  const [anchorEl, setAnchorEl] = useState(null);
  const [ChatCount, setCount] = useState(0);
  const user = useSelector((state) => state?.Reducer?.user);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const GetData = () => {
    if (user && user?.id) {
      setData(user);
    }
  };
  useEffect(() => {
    GetData();
  }, [user]);

  useEffect(() => {
    GetStories();
  }, []);

  const GetStories = () => {
    ApiClient.get("stories").then((res) => {
      if (res.success) {
        setStory(res?.data);
      }
    });
  };

  const PostStory = () => {
    ref.current.staticStart();

    ApiClient.post("story", Form).then((res) => {
      if (res.success) {
        toast.success(res.message);
        GetStories();
      }
    });
    ref.current.complete();
  };

  const SubmitImage = async (e) => {
    ref.current.staticStart();

    let file = e.target.files[0];
    let FORM = new FormData();
    FORM.append("file", file);

    let Upload = axios
      .post(`${environment.apiUrl}documentUpload`, FORM, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data?.success) {
          ref.current.complete();
          setform({
            ...Form,
            image: `${environment.apiUrl}${res?.data?.data.path}`,
          });
          // toast.success("Image Uploaded Successfully");
          toast.custom((t) => (
            <div
              className={`${
                t.visible ? "animate-enter" : "animate-leave"
              } max-w-md w-full  bg-white shadow-lg rounded-lg pointer-events-auto flex-col flex ring-1 ring-black ring-opacity-5`}
            >
              <div className="flex-1 w-full p-4">
                <div className="flex items-start w-full">
                  <div className="flex-shrink-0 pt-0.5 w-full">
                    <img
                      className="h-[30vh] w-[100%] rounded-2xl"
                      src={`${environment.apiUrl}${res?.data?.data.path}`}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="flex border-l border-gray-200">
                <button
                  onClick={() => toast.dismiss(t.id)}
                  className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  Close
                </button>
              </div>
            </div>
          ));
        }
      });
  };

  useEffect(() => {
    if (user && user?.id) {
      SocketRef.current = io(environment.SocketIoUrl, {
        auth: {
          Authorization: localStorage.getItem("token"),
        },
      });
      SocketRef.current.emit("online", { user_id: user?.id });
      setInterval(() => {
        getChatCount();
      }, 700);
    }

    return () => {
      io(environment.SocketIoUrl).disconnect();
      SocketRef?.current?.disconnect();
    };
  }, []);

  const getChatCount = () => {
    SocketRef.current.on("chatNotify", async (data) => {
      setCount(data?.count);
    });
  };

  return (
    <>
      <LoadingBar shadow={true} height={3} color="red" ref={ref} />

      <div className="w-[100%] lg:w-[23%] h-[98vh] ml-1 shadow-gray-500 items-center flex flex-col justify-start shadow-2xl rounded-2xl">
        <div className="w-full h-[12vh] shadow-lg rounded-lg flex justify-around items-center">
          <input
            className="hidden"
            type="file"
            name=""
            id="StoryInput"
            onChange={SubmitImage}
          />
          <Box
            sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
          >
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <Avatar
                  src={`${data?.image}`}
                  sx={{ width: 32, height: 32 }}
                ></Avatar>
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem
              onClick={() => {
                handleClose();
                history("/profile");
              }}
            >
              <Avatar src={`${data?.image}`} /> Profile
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                history("/profile");
              }}
            >
              <Avatar src={`${data?.image}`} /> My account
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <PersonAdd fontSize="small" />
              </ListItemIcon>
              Add another account
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                history("/edit-profile");
              }}
            >
              <ListItemIcon>
                <Settings fontSize="small" />
              </ListItemIcon>
              Settings
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                toast.success("Logout successfuly");
                Dispatch(LOGOUT_SUCCESS());
                history("/login");
              }}
            >
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>

          {/* <!-- Dropdown menu --> */}

          <HiHome className="cursor-pointer" size={25} color="yellow" />
          {/* <SlSettings className="cursor-pointer" size={25} /> */}
          <GrSnapchat className="cursor-pointer" size={25} color="yellow" />
          <div>
            <TbMessage
              className="cursor-pointer"
              onClick={() => {
                history("/chat");
              }}
              size={25}
            />
            <span class="bg-yellow-100 absolute rounded-full  top-7 text-yellow-800 text-sm font-medium me-2 w-5 h-5 dark:bg-yellow-200 dark:text-yellow-300">
              {ChatCount}
            </span>
          </div>
        </div>
        <div className="w-[97%] shadow-2xl rounded-xl flex flex-col items-start shadow-black h-[70vh] mt-3">
          <h1 className="text-xl ml-2 mt-2">Trending For You</h1>

          <div class="w-full max-w-md p-4 bg-inherit rounded-lg shadow sm:p-8 ">
            <div class="flex items-center justify-between mb-4">
              {/* <h5 class="text-xl font-bold leading-none text-gray-900 dark:text-white">Latest Customers</h5> */}
              {/* <a href="#" class="text-sm font-medium text-blue-600 hover:underline dark:text-blue-500">
            View all
        </a> */}
            </div>
            <div class="flow-root">
              <ul
                role="list"
                class="divide-y divide-gray-200 dark:divide-gray-700"
              >
                <li class="py-3 sm:py-4">
                  <div class="flex items-center">
                    <div class="flex-shrink-0">
                      <img
                        class="w-8 h-8 rounded-full"
                        src={`${data?.image}`}
                        alt=""
                      />
                    </div>
                    <div class="flex-1 min-w-0 ms-4">
                      <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                        {Story?.some((itm) => itm?.addedBy?.id == data?.id) ? (
                          <p
                            className="cursor-pointer"
                            onClick={() => {
                              Swal.fire({
                                // title: "Sweet!",
                                text: Story[0]?.title,
                                imageUrl: `${environment.apiUrl}${Story[0]?.image}`,
                                imageWidth: 400,
                                imageHeight: 200,
                                imageAlt: "",
                              });
                            }}
                          >
                            View Story
                          </p>
                        ) : (
                          <p>Share Story</p>
                        )}
                      </p>
                    </div>
                    <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                      <IoMdCloudUpload
                        size={"3vh"}
                        className="cursor-pointer"
                        onClick={() => {
                          if (!Form?.image) {
                            document.getElementById("StoryInput").click();
                          } else {
                            PostStory();
                          }
                        }}
                      />
                    </div>
                  </div>
                </li>
                {Story?.map((itm) => {
                  return (
                    <>
                      {itm?.addedBy?.id != data?.id && (
                        <li class="py-3 sm:py-4">
                          <div class="flex items-center">
                            <div class="flex-shrink-0">
                              <img
                                class="w-8 h-8 rounded-full"
                                src={`${environment.apiUrl}${itm?.addedBy?.image}`}
                                alt=""
                              />
                            </div>
                            <div class="flex-1 min-w-0 ms-4">
                              <p
                                onClick={() => {
                                  Swal.fire({
                                    imageUrl: `${environment.apiUrl}${itm?.image}`,
                                    imsageWidth: 400,
                                    imageHeight: 200,
                                    imageAlt: "",
                                  });
                                }}
                                class="text-sm font-medium text-gray-900 truncate dark:text-white"
                              >
                                {itm?.title}
                              </p>
                            </div>
                            {/* <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                              $320
                            </div> */}
                          </div>
                        </li>
                      )}
                    </>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <button className="px-20 py-2 rounded-lg mt-5 bg-yellow-300">
          Share
        </button>
      </div>
    </>
  );
}
