import Chat from "./Chat";
import React, { useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import dateFormat from "dateformat";
import ApiClient from "../../Apis/ApiClient";
import { IoCheckmark } from "react-icons/io5";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import EmojiPicker from "emoji-picker-react";
import LoadingBar from "react-top-loading-bar";
import moment from "moment";
import environment from "../../Environment/environment";
import { toast } from "react-hot-toast";
import { ToastBar } from "react-hot-toast";
import axios from "axios";
import Swal from "sweetalert2";
const ChatPage = () => {
  const [Sent, setSent] = useState([]);
  const [form, setform] = useState({});
  const [isFileOpen, setisFileOpen] = useState(false);
  const [user, setUser] = useState({});
  const [People, setPeoples] = useState([]);
  const [ChatUser, setChatUser] = useState("");
  const [Received, setReceived] = useState([{ data: [] }]);
  let [Data, setData] = useState([]);
  const [Room_id, setRoom_id] = useState("");
  const SocketRef = useRef(null);

  const [connectedUser, SetConnect] = useState({});
  const [display, setDisplay] = useState(false);
  const ref = useRef(null);
  let [ChatFile, setFiles] = useState("");
  const UserID = localStorage.getItem("user_id");
  const [Typing, setIsTyping] = useState(false);
  useEffect(() => {
    ref.current.staticStart();
    ref.current.complete();
  }, []);
  useEffect(() => {
    SocketRef.current = io(environment.SocketIoUrl);
    SocketRef.current.on("error", async (data) => {
      console.log(data);
      toast.error(data?.message);
    });

    setInterval(() => {
      GetTypingEvent();
    }, 300);

    return () => {
      SocketRef.current.disconnect();
      io(environment.SocketIoUrl).disconnect();
    };
  }, []);

  const GetTypingEvent = () => {
    SocketRef.current.on("isTyping", async (data) => {
      if (
        data?.user_id == localStorage.getItem("ConnectedUser") &&
        data?.isTyping == true
      ) {
        setIsTyping(true);
      } else {
        setIsTyping(false);
      }
    });
  };
  const GetFileSelectEvent = () => {
    SocketRef.current.on("isSelectingFile", async (data) => {
      if (
        data?.user_id == localStorage.getItem("ConnectedUser") &&
        data?.isTyping == true
      ) {
        setisFileOpen(true);
      } else {
        setisFileOpen(false);
      }
    });
  };
  const GetProfile = () => {
    ref.current.staticStart();
    ApiClient.get("profile").then((res) => {
      if (res.success) {
        setUser(res?.data);
        SocketRef.current.emit("online", { user_id: res?.data?.id });
        ref.current.complete();
        localStorage.setItem("user_id", res?.data?.id);
      }
      ref.current.complete();
    });
  };
  window.addEventListener("beforeunload", () => {
    SocketRef?.current?.emit("disconnectUser", {
      user_id: localStorage.getItem("user_id"),
    });
  });

  const GetUsers = () => {
    ApiClient.get("getUser?count=10").then((res) => {
      if (res?.success) {
      }
    });
  };

  const GetFollows = () => {
    ApiClient.get("follows").then((res) => {
      if (res.success) {
        if (res?.data?.details) {
          let totalPeoples = [
            ...res?.data?.details?.followers,
            ...res?.data?.details?.followings,
          ];
          if (totalPeoples?.length) {
            setPeoples(totalPeoples);
          }
        }
      }
    });
  };

  useEffect(() => {
    GetProfile();
    GetUsers();
    GetFollows();
  }, []);
  const UpdateChatWithUser = (itm) => {
    ref.current.staticStart();

    SocketRef.current.emit("join_room", { to: itm?.id, from: user?.id });
    SocketRef.current.once("ConnectToRoom", async (data) => {
      SetConnect({
        Room_id: data?.room_id,
        userName:
          data?.data?.to?.id == user?.id
            ? data?.data?.from?.name
            : data?.data?.to?.name,
        user_id:
          data?.data?.to?.id == user?.id
            ? data?.data?.from?.id
            : data?.data?.to?.id,
        userEmail:
          data?.data?.to?.id == user?.id
            ? data?.data?.from?.email
            : data?.data?.to?.email,
        image:
          data?.data?.to?.id == user?.id
            ? data?.data?.from?.image
            : data?.data?.to?.image,
        isOnline: itm?.isOnline,
        lastSeen: itm?.lastSeen,
      });
      localStorage.setItem(
        "ConnectedUser",
        data?.data?.to?.id == user?.id
          ? data?.data?.from?.id
          : data?.data?.to?.id
      );
      setRoom_id(data?.room_id);
      localStorage.setItem("SocketRoomID", data?.room_id);
      localStorage.setItem("RoomID", data?.data?._id);
      ref.current.complete();
      SocketRef.current.on("retrive_old_chat", async (resp) => {
        setReceived(resp);
      });
    });

    return () => {
      SocketRef.current.off("ConnectToRoom", async (data) => {});
      SocketRef.current.off("retrive_old_chat", async (data) => {});
    };
  };
  let audio = new Audio(
    "https://audio.jukehost.co.uk/8lZMdDgwlzs8EdHiIDde06pKLl6uP8Gq"
  );

  const SendMessage = (e) => {
    if (e) {
      e.preventDefault();
    }
    isTyping(false);
    document.getElementById("MainMessageInput").blur();

    SocketRef.current.emit("chat_message", {
      to: connectedUser?.user_id,
      from: user?.id,
      message: form?.emoji ? form?.message + form?.emoji : form?.message,
    });
    AlertMsg.pause();
    let ScrollDiv = document.getElementById("ScrollChatDiv");
    ScrollDiv.scrollTop = ScrollDiv.scrollHeight;
    setform({ message: "", emoji: "" });
    SocketRef.current.on("receive_message", async (data) => {
      setReceived(data);
      let ScrollDiv = document.getElementById("ScrollChatDiv");
      ScrollDiv.scrollTop = ScrollDiv.scrollHeight;
      SocketRef.current.emit("UpdateLastSeen", { user_id: user?.id });
    });
  };

  useEffect(() => {
    setInterval(() => {
      ReceiveMessage();
    }, 100);
  }, []);
  let AlertMsg = new Audio(
    "https://audio.jukehost.co.uk/Sj7OzMRla1MOjcepIbiqNO3r2g4IkyP2"
  );
  const ReadLastMessage = (data) => {
    SocketRef.current.emit("SeenLastMessage", {
      message_id: data?._id,
      room_id: data?.room_id,
      sender: localStorage.getItem("ConnectedUser"),
    });
  };
  const ReceiveMessage = () => {
    SocketRef.current.on("receive_message", async (data) => {
      setReceived(data);
      let LastMessage = data?.data[data?.data?.length - 1];

      audio.pause();
      if (LastMessage?.sender?.id != localStorage.getItem("user_id")) {
        AlertMsg.play();
      }

      if (LastMessage?.receiver?.id == localStorage.getItem("user_id")) {
        ReadLastMessage(LastMessage);
      }
    });
  };

  const GoOffline = () => {
    io("http://localhost:3500").disconnect();

    SocketRef.current.emit("disconnectUser", { user_id: user?.id });
  };

  const HandleUploadDocument = async (e) => {
    let file = e.target.files[0];
    let FORM = new FormData();
    FORM.append("file", file);

    let Upload = axios
      .post(`${environment.apiUrl}documentUpload`, FORM, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((resp) => {
        Swal.fire({
          title: "",
          text: "Are Sure to Send This ?",
          imageUrl: `${environment.apiUrl}${resp?.data?.data?.path}`,
          imageWidth: 200,
          imageHeight: 200,
          confirmButtonText: "Send",
          denyButtonText: "Cancel",
        }).then((res) => {
          if (res?.isConfirmed) {
            SocketRef.current.emit("chat_message", {
              to: connectedUser?.user_id,
              from: user?.id,
              message: form?.emoji
                ? form?.message + form?.emoji
                : form?.message,
              file: `${environment.apiUrl}${resp?.data?.data?.path}`,
            });
            ReceiveMessage();
          }
        });
      })
      .catch((err) => {
        toast.error(err?.data?.message);
      });
    // let UploadFile = await fetch(`${environment.apiUrl}documentUpload`,{
    //   method:"POST",
    //   body:FORM,
    //   headers:{
    //     "Authorization":`${localStorage.getItem("token")}`,
    //     "accept":"application/json",
    //     "Content-Type":`multipart/form-data`
    //   }
    // })
  };

  const DistinguishFileType = (file) => {
    var re = /(?:\.([^.]+))?$/;
    let fileType = re.exec(file)[0];
    console.log(fileType);
    return fileType;
  };

  const isTyping = (typing) => {
    SocketRef.current.emit("typing", {
      user_id: user?.id,
      room_MongoID: localStorage.getItem("RoomID"),
      room_id: localStorage.getItem("SocketRoomID"),
      isTyping: typing,
    });
  };

  const isSelectingFile = (typing) => {
    SocketRef.current.emit("uploadFile", {
      user_id: user?.id,
      room_MongoID: localStorage.getItem("RoomID"),
      room_id: localStorage.getItem("SocketRoomID"),
      isSelecting: typing,
    });
  };

  const ExitChat = () => {
    localStorage.removeItem("RoomID");
    SocketRef.current.emit("ExitChat", {
      room_id: localStorage.getItem("SocketRoomID"),
      user_id: user?.id,
    });
    SetConnect({});
    setRoom_id("");
    setReceived([]);
  };
  return (
    <>
      <LoadingBar shadow={true} height={3} color="yellow" ref={ref} />
      <Chat
        Sent={Sent}
        HandleUploadDocument={HandleUploadDocument}
        form={form}
        DistinguishFileType={DistinguishFileType}
        setSent={setSent}
        user={user}
        setUser={setUser}
        setform={setform}
        People={People}
        setPeoples={setPeoples}
        ChatUser={ChatUser}
        setChatUser={setChatUser}
        Received={Received}
        setReceived={setReceived}
        Data={Data}
        setData={setData}
        Room_id={Room_id}
        isSelectingFile={isSelectingFile}
        isFileOpen={isFileOpen}
        setisFileOpen={setisFileOpen}
        setRoom_id={setRoom_id}
        SocketRef={SocketRef}
        connectedUser={connectedUser}
        SetConnect={SetConnect}
        display={display}
        setDisplay={setDisplay}
        UserID={UserID}
        ref={ref}
        GetProfile={GetProfile}
        GetUsers={GetUsers}
        UpdateChatWithUser={UpdateChatWithUser}
        SendMessage={SendMessage}
        ReceiveMessage={ReceiveMessage}
        GoOffline={GoOffline}
        Typing={Typing}
        setIsTyping={setIsTyping}
        isTyping={isTyping}
        ExitChat={ExitChat}
      />
    </>
  );
};
export default ChatPage;
