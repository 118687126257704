import moment from 'moment';
import React from 'react'

function Sidebar({ People, user, UpdateChatWithUser }) {
    return (
        <>


            <div class="text-center block md:hidden">
                <button id='UsersSideBar' hidden class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" type="button" data-drawer-target="drawer-example" data-drawer-show="drawer-example" aria-controls="drawer-example">
                    User List
                </button>
            </div>

            <div id="drawer-example" class="fixed top-0 left-0 z-40 h-screen p-4 overflow-y-auto transition-transform -translate-x-full bg-white w-[100%] sm:w-96 xl:w-80 dark:bg-gray-800" tabindex="-1" aria-labelledby="drawer-label">
                <h5 id="drawer-label" class="inline-flex items-center mb-4 text-base font-semibold text-gray-500 dark:text-gray-400"><svg class="w-4 h-4 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                </svg>Users</h5>
                <button type="button" data-drawer-hide="drawer-example" aria-controls="drawer-example" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white" >
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                    </svg>
                    <span class="sr-only">Close menu</span>
                </button>



                <div className="flex flex-col flex-auto flex-shrink-0 h-[90vh]    rounded-2xl   w-[100%]  scrollbar-hide">

                    {People &&
                        People?.map((itm) => {
                            return (
                                <>
                                    {itm?.id != user?.id && (
                                        <div
                                            className="flex justify-around items-center shadow-sm bg-yellow-50 rounded-xl mt-2"
                                            onClick={() => {
                                                UpdateChatWithUser(itm);
                                            }}
                                        >
                                            <img
                                                className="rounded-full w-12 py-1 h-12"
                                                src="https://cdn-icons-png.freepik.com/256/3334/3334886.png"
                                                alt=""
                                            />
                                            <span className="text-sm">{itm?.name}</span>
                                            <span className="text-sm text-lime-500">
                                                {itm?.isOnline
                                                    ? "Online"
                                                    : `Last seen at ${moment(itm?.lastSeen).format(
                                                        "h:m A"
                                                    )}`}
                                            </span>
                                            {itm?.isOnline ? (
                                                <span className="w-2 h-2 rounded-full bg-lime-500"></span>
                                            ) : null}
                                        </div>
                                    )}
                                </>
                            );
                        })}

                </div>
            </div>

        </>
    )
}

export default Sidebar
