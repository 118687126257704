import React, { useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import dateFormat from "dateformat";
import ApiClient from "../../Apis/ApiClient";
import { IoCheckmark } from "react-icons/io5";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { TbUsers } from "react-icons/tb";
import { IoExit } from "react-icons/io5";

import EmojiPicker from "emoji-picker-react";
import LoadingBar from "react-top-loading-bar";
import moment from "moment";
import environment from "../../Environment/environment";
import { toast } from "react-hot-toast";
import { ToastBar } from "react-hot-toast";
import SideBar from "../../Components/Sidebar/Sidebar";
import { Tooltip } from "antd";
// const socket = io.connect("http://localhost:3300");
function Chat({
  Sent,
  form,
  setSent,
  user,
  setUser,
  ExitChat,
  People,
  setPeoples,
  ChatUser,
  setChatUser,
  Received,
  setReceived,
  Data,
  setisFileOpen,
  isFileOpen,
  isSelectingFile,
  setform,
  setData,
  Room_id,
  setRoom_id,
  SocketRef,
  connectedUser,
  SetConnect,
  display,
  setDisplay,
  isTyping,
  setIsTyping,
  Typing,
  UserID,
  ref,
  GetProfile,
  GetUsers,
  UpdateChatWithUser,
  SendMessage,
  ReceiveMessage,
  GoOffline,
  HandleUploadDocument,
  DistinguishFileType,
}) {
  return (
    <>
      {/* <LoadingBar shadow={true} height={3} color="yellow" ref={ref} /> */}
      <div class="flex h-[100vh] w-[100%] antialiased text-gray-800">
        <SideBar
          People={People}
          user={user}
          UpdateChatWithUser={UpdateChatWithUser}
        />
        <div class="flex flex-row h-full  overflow-x-hidden w-[100%]">
          <div className="w-[35%] h-[100vh] hidden sm:hidden md:flex xl:flex 2xl:flex  justify-center items-center ">
            <div className="flex flex-col flex-auto flex-shrink-0 h-[90vh] ml-5 shadow-2xl shadow-black rounded-2xl bg-gray-100  p-4 w-[100%]  scrollbar-hide">
              {People &&
                People?.map((itm) => {
                  return (
                    <>
                      {itm?.id != user?.id && (
                        <div
                          className="flex justify-around items-center bg-yellow-50 rounded-xl mt-2"
                          onClick={() => {
                            UpdateChatWithUser(itm);
                          }}
                        >
                          <img
                            className="rounded-full w-12 py-1  h-12"
                            src={
                              itm?.image
                                ? `${itm?.image}`
                                : '"https://cdn-icons-png.freepik.com/256/3334/3334886.png"'
                            }
                            alt=""
                          />
                          <span className="text-sm">{itm?.name}</span>
                          <span className="text-sm text-lime-500">
                            {itm?.isOnline
                              ? "Online"
                              : `Last seen at ${
                                  moment(itm?.lastSeen).format("DD-MM-YYYY") ==
                                  moment().format("DD-MM-YYYY")
                                    ? moment(itm?.lastSeen).format("h:mm A")
                                    : moment(itm?.lastSeen).format(
                                        "DD-MMM-YYYY h:mm A"
                                      )
                                }`}
                          </span>
                          {itm?.isOnline ? (
                            <span className="w-2 h-2 rounded-full bg-lime-500"></span>
                          ) : null}
                        </div>
                      )}
                    </>
                  );
                })}
            </div>
          </div>
          <div
            class={`flex flex-col flex-auto h-full md:p-6 w-[100%] ${
              connectedUser?.user_id ? "" : "blur-xl"
            }`}
          >
            <div class="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 h-full p-4 w-[100%]  scrollbar-hide">
              <div
                id="ScrollChatDiv"
                class="flex flex-col h-full overflow-y-scroll mb-4 scrollbar-hide "
                onClick={() => {
                  setDisplay(false);
                }}
              >
                <div class="flex flex-col h-full">
                  <div class="grid grid-cols-12 gap-y-2 ">
                    <div class="col-start-1 col-end-8 p-2 rounded-lg w-[90%] md:w-[30%] bg-yellow-300 flex  justify-between items-center fixed z-50">
                      <div class="flex flex-row items-center">
                        <div class="flex items-center justify-center h-10 w-10 rounded-full  flex-shrink-0">
                          {/* {itm.user.split('')[0]} */}
                          <img
                            className="rounded-full w-12 py-1 h-12"
                            onClick={() => {
                              GoOffline();
                            }}
                            src={
                              connectedUser?.image
                                ? `${connectedUser?.image}`
                                : "https://cdn-icons-png.freepik.com/256/3334/3334886.png"
                            }
                            alt=""
                          />
                        </div>
                        <div class="  ml-3 text-sm bg-white  py-2 px-4 shadow rounded-xl  flex flex-col justify-end items-center ">
                          <div className="flex w-full justify-end items-start flex-col">
                            <div>{connectedUser?.userName}</div>
                            <div>
                              {Typing ? (
                                "Typing...."
                              ) : (
                                <>
                                  {connectedUser?.isOnline
                                    ? "Online"
                                    : moment(connectedUser?.lastSeen).format(
                                        "DD-MMM-YYYY hh:mm A"
                                      )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex">
                        <IoExit className="cursor-pointer" onClick={()=>{
                          ExitChat()
                        }} size={20} />

                        <TbUsers
                          className="cursor-pointer ml-3"
                          onClick={() => {
                            document.getElementById("UsersSideBar").click();
                          }}
                          size={20}
                        />
                      </div>
                    </div>
                    {Received?.data &&
                      Received?.data.map((itm, i) => {
                        return (
                          <>
                            {itm?.sender?.id == connectedUser?.user_id ? (
                              <div
                                key={i}
                                id={`MessageNo${i}`}
                                class="col-start-1 col-end-8 p-3 rounded-lg"
                              >
                                <div class="flex flex-row items-center">
                                  <div class="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0">
                                    {/* {itm.user.split('')[0]} */}
                                    <img
                                      src={`${connectedUser?.image}`}
                                      className="w-10 h-10 rounded-full"
                                      alt=""
                                    />
                                  </div>
                                  <div class="relative ml-3 text-sm bg-white py-2 px-4 shadow rounded-xl flex flex-col justify-end items-center ">
                                    <div>{itm.content}</div>
                                    {itm?.file ? (
                                      <>
                                        <div>
                                          {DistinguishFileType(itm?.file) ==
                                          ".pdf" ? (
                                            <>
                                              <iframe
                                                src={`${environment.apiUrl}${itm?.file}`}
                                                frameborder="0"
                                              ></iframe>
                                            </>
                                          ) : (
                                            <img
                                              className="h-[250px]"
                                              src={`${environment.apiUrl}${itm?.file}`}
                                              alt=""
                                            />
                                          )}
                                        </div>
                                      </>
                                    ) : null}
                                    <div className="flex w-full justify-end items-end">
                                      {moment(itm?.createdAt).format("hh:mm A")}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div class="col-start-6 col-end-13 p-3 rounded-lg ">
                                <div class="flex items-center justify-start flex-row-reverse">
                                  <div class="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0">
                                    <img
                                      src={`${user?.image}`}
                                      className="w-10 h-10 rounded-full"
                                      alt=""
                                    />
                                  </div>
                                  <div class="relative mr-3 text-sm bg-indigo-100 py-2 px-4 shadow rounded-xl flex justify-end items-center flex-col">
                                    <div>{itm?.content}</div>
                                    {itm?.file ? (
                                      <>
                                        <div>
                                          {itm?.file?.includes(".pdf") ? (
                                            <>
                                              <iframe
                                                src={`${environment.apiUrl}${itm?.file}`}
                                                frameborder="0"
                                              ></iframe>
                                            </>
                                          ) : (
                                            <img
                                              className="h-[250px]"
                                              src={`${environment.apiUrl}${itm?.file}`}
                                              alt=""
                                            />
                                          )}
                                        </div>
                                      </>
                                    ) : null}
                                    <div className="w-full flex justify-end items-center">
                                      {moment(itm?.createdAt).format("hh:mm A")}
                                      {itm?.isSeen ? (
                                        <IoCheckmarkDoneSharp color="green" />
                                      ) : (
                                        <IoCheckmark />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>
              {Typing ? (
                <div className="flex mb-3 justify-center items-center w-[50%] md:w-[30%] ">
                  <img
                    className="w-28 rounded-2xl mr-4"
                    src="https://principlerepo.com/wp-content/uploads/2017/03/wsi-imageoptim-typing-status.gif"
                    alt=""
                  />
                  {connectedUser?.userName} is typing...
                </div>
              ) : null}
              <div class="flex flex-row items-center h-16 rounded-xl bg-white w-full px-4">
                <div>
                  <button
                    onClick={() => {
                      document.getElementById("ChatFileUpload").click();
                    }}
                    class="flex items-center justify-center text-gray-400 hover:text-gray-600"
                  >
                    <svg
                      class="w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                      ></path>
                    </svg>
                  </button>
                </div>

                <form className="flex-grow flex" onSubmit={SendMessage}>
                  <div class="flex-grow ml-4">
                    <div class="relative w-full">
                      <input
                        id="MainMessageInput"
                        type="text"
                        disabled={!connectedUser?.user_id}
                        value={form?.message}
                        onFocus={() => {
                          isTyping(true);
                        }}
                        onBlur={() => {
                          isTyping(false);
                        }}
                        onChange={(e) => {
                          setform({ ...form, message: e.target.value });
                          console.log(Sent, "==================Sent");
                          console.log(Received, "==================Received");
                        }}
                        class="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10"
                      />

                      <button class="absolute hidden md:flex items-center justify-center h-full w-12 right-0 top-0 text-gray-400 hover:text-gray-600">
                        <svg
                          onClick={() => {
                            if (display == false) {
                              setDisplay(true);
                            }
                            if (display == true) {
                              setDisplay(false);
                            }
                          }}
                          class="w-6 h-6"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          ></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div class="ml-4">
                    <button
                      disabled={!connectedUser?.user_id}
                      type="submit"
                      class="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-1 sm:px-4 py-1 flex-shrink-0"
                    >
                      <span>Send</span>
                      <span class="ml-2">
                        <svg
                          class="w-4 h-4 transform rotate-45 -mt-px"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                          ></path>
                        </svg>
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {display ? (
          <EmojiPicker
            searchDisabled
            onEmojiClick={(e) => {
              let FullMessage = form?.message + e.emoji;
              setform({ ...form, message: FullMessage });
            }}
            className="absolute top-[40vh] right-[14%]"
            height={"50vh"}
            width={"700px"}
          />
        ) : null}
      </div>
      <input
        disabled={!connectedUser?.user_id}
        type="file"
        className="hidden"
        onFocus={() => {
          isSelectingFile(true);
        }}
        onBlur={() => {
          isSelectingFile(false);
        }}
        onChange={HandleUploadDocument}
        accept={[".png", ".jpg", ".jpeg", ".pdf"]}
        id="ChatFileUpload"
      />
    </>
  );
}

export default Chat;
